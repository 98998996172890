.sidebar-container {

    background-color: black;
    width: 250px;
    min-width: 200px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    /* background-color: black;
    width: 20vw;
    min-width: 200px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2; */

    /* max-width: 30vw; */
    /* grid-area: sideBar; */
    /* display: flex;
    flex-direction: column;
    width: 20%;
    position: fixed;
    z-index: 100; */
    /* height: 100%; */
}

.banner {
    margin-top: 20px;
    display: flex;
    margin-bottom: 18px;
    padding: 0 24px;
}

.banner img {
    max-width: 100%;
    max-height: 100%
}

.banner h2 {
    color: white;
    text-align: left;
    margin: 10px;
    font-size: 24px;
    font-weight: 600;
    max-width: 100%;
    max-width: 100%
}


.sidebar-nav {
    height: 85vh;
    display: flex;
    flex-direction: column;
}

.sidebar-home-search-library-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.sidebar-home {
    display: flex;
    margin: auto;
    /* padding-left: 24px; */
    align-items: center;
    gap: 16px;
    height: 40px;
    padding: 0 24px;
}

.sidebar-home li {
    display: flex;
}

.sidebar-home h3 {
    font-weight: 700;
    font-size: 0.9rem;
    margin-left: 18px;
}

.sidebar-nav .active {
    text-decoration: none;
    color: white;
}

.sidebar-nav .not-active {
    text-decoration: none;
    color: #b3b3b3;
}

.sidebar-playlists-section-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    margin-top: 24px;
    position: relative;
}


.sidebar-playlists-create-and-likes-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.playlists-create-playlist-container {
    display: flex;
    padding: 0 24px;
    /* transition-property: color; */
}

.playlists-create-playlist-container:hover .create-playlists-label {
    color: white;
}

.create-playlists-button {
    display: flex;
    align-items: center;
    width: 18px;
    height: 40px;
}

.create-playlist-button-icon {
    font-size: 1.2rem;
    width: 100%;
    color: rgb(209, 209, 209);
    transition-duration: .2s;
    transition-property: color;
}

.create-playlist-button-icon:hover {
    color: white;
}

.create-playlists-label {
    display: flex;
    align-items: center;
    margin-left: 18px;
    /* color: white; */
    color: #b3b3b3;
    transition-duration: .2s;
    transition-property: color;
    font-weight: 700;
    font-size: 0.9rem;
}


.linebreak-between-playlist-containers {
    position: relative;
}

.linebreak-between-playlist-containers hr{
    background-color: #282828;
    border: none;
    height: 1px;
    margin: 8px 24px;
    min-height: 1px;
}


.hide-playlists-create-playlist-container {
    display: flex;
        padding: 0 24px;
}