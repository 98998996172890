.track-playlist-item-container {}


.track-playlist-item {
    border: 1px solid transparent;
    border-radius: 4px;
    height: 56px;
    position: relative;
    padding: 0px 10px;
    display: flex;
    align-items: center;
}

.track-playlist-item:hover {
    background-color: hsla(0, 0%, 100%, .1);
}

.track-playlist-item-info {
    margin-left: 20px;
    width: 50%;
}

.track-playlist-item-title {
    font-size: 0.8rem;
    font-weight: 500;
    padding-block: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.track-playlist-item-artist {
    color: rgb(161, 161, 161);
    padding-block: 0.2rem;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.track-playlist-item-album-link{
    width: 30%;
    color: rgb(161, 161, 161);
    padding-block: 0.2rem;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}


.track-playlist-item-duration {
    width: 10%;
    text-align: right;
    color: rgb(161, 161, 161);
    padding-block: 0.2rem;
    font-size: 0.8rem;
}

.track-playlist-item:hover .track-playlist-item-artist {
    color: white;
}

.track-playlist-item:hover .track-playlist-item-album-link {
    color: white;
}

.track-playlist-item-album-link:hover {
    text-decoration: underline;
}

.playlist-track-update-container {
    width: 10%;
    display: flex;
}