.playlist-show-flex-container {
    display: flex;
    width: 100%;
    height: 100vh;
    /* display: grid;
    grid-template-areas:
            "sideBar navBar navBar"
            "sideBar main main"
            "sideBar main main"
            ;
    grid-template-columns: 30vh; */
}

.non-play-bar-section {
    width: 100%;
    display: flex;
}

.playlist-show-nav-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 250px;
    /* min-width: 800px; */
    left: 0;
    position: relative;
    /* margin-bottom: 80px; */
}


.playlist-show-container {
    /* grid-area: main; */
    background-color: #1c1c1c;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 80px;
    height: 100%;
}

.playlist-show-header {
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-bottom: 2.5vh;
}

.playlist-show-image {
    margin-right: 24px;
    display: flex;
    height: 100%;
    width: 20%;
    min-width: 200px;
}

.playlist-show-image img {
    max-width: 100%;
    max-height: 100%;
}


.playlist-show-info {
    display: flex;
    flex-direction: column;
}

.extra-info {
    display: flex;
    flex-direction: row;
}

.component-type {
    font-weight: 700;
}

.playlist-show-info.playlist-title {
    margin: 0.08em 0px 0.12em;
    visibility: visible;
    width: 100%;
    font-weight: 800;
    font-size: 6rem;
}

.extra-info {
    font-size: 14px;
}

.playlist-creator-name {
    color: white;
    font-weight: 700;
    margin-left: 5px;
    font-size: 14px;
}

.playlist-year:before {
    content: "•";
    margin: 0 4px;
}

.playlist-track-count:before {
    content: "•";
    margin: 0 4px;
}

/* .playlist-duration:before {
    content: "•";
    margin: 0 4px;
} */

.playlist-show-item.playlist-show-play-area {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 54px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.delete-playlist-text {
    padding-left: 10px;
}

.playlist-tracks-label-holder {
    top: 64px;
    border-bottom: 1px solid transparent;
    box-sizing: content-box;
    height: 36px;
    margin: 0 -16px 8px;
    padding: 0 16px;
    /* position: sticky; */
    z-index: 2;
}

.playlist-tracks-label {
    font-size: 13px;
    border-bottom: 1px solid hsla(0, 0%, 100%, .1);
    color: #b3b3b3;
    height: 36px;
    display: flex;
    padding: 0px 10px;
    align-items: center;
}

.tracks-title-indicator {
    padding-left: 15px;
    width: 50%;
    box-sizing: border-box;
}

.tracks-album-indicator {
    padding-left: 5px;
    width: 30%;
    box-sizing: border-box;
}

.tracks-duration-icon-container {
    display: flex;
    width: 9.65%;
    height: auto;
    justify-content: flex-end;
}

.tracks-duration-icon-container img {
    max-width: 10%;
}