.album-index-item-link {
    text-decoration: none;
}

.album-index-item {
    height: 230px;
    width: 150px;
    /* height: 20vh;
    width: 14vh; */
    /* min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 230px; */
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    isolation: isolate;
    cursor: pointer;
    transition: background-color .3s;
    background: #252525;
    color: white;
    font-weight: 16px;
}


.album-index-item:hover {
    background: rgb(57, 56, 56);
}

.album-index-item-play-button {
    position: absolute;
    opacity: 0;
    right: 22px;
    bottom: 32px;
    transform: translateY(12px);
    transition: all .3s;
}

.album-index-item:hover .album-index-item-play-button {
    opacity: 1;
    transform: translateY(8px);
    /* transform: translateY(0px); */
}

.album-image {
    position: relative;
    height: 66%;
    width: 100%;
}


.album-image img {
    width: 100%;
    height: auto;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .5);
}

i.fa-solid.fa-circle-play.fa-2xl {
    font-size: 50px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .5);
    transform: scale(1);
}

i.fa-solid.fa-circle-play.fa-2xl:hover {
    transform: scale(1.08);
    color: rgb(58 239 32);
}

.fa-circle-play:before,
.fa-play-circle:before {
    content: "\f144";
    /* display: flex;
    background-color: black;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center; */
}

.album-title {
    font-size: 1rem;
    font-weight: 700;
    width: none;
    padding-block: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.album-index-album-year {
    color: rgb(161, 161, 161);
    font-size: 0.8rem;
}

.album-index-artist-name:before {
    content: "•";
    margin: 0 4px;
}

.album-index-artist-name {
    color: rgb(161, 161, 161);
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}