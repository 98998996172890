.splash-page-flex-container {
    display: flex;
    width:100%
    /* display: grid;
    grid-template-areas:
        "sideBar navBar navBar"
        "sideBar main main"
        "sideBar main main" ; */
}

.splash-page-container {
    /* grid-area: main; */
    display:flex;
    flex-direction: column;
    background-color: #1c1c1c;
    width: 100%;
    padding-left: 3vw;
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;
    /* position: relative;
    left: 20%;
    width: 80%;
    background-color: #1c1c1c;
    padding: 20px; */
}

.time {
    color: white;
    font-size: 40px;
    font-weight: 700;
    padding: 20px 10px;
}

.start-listening {
    color: white;
    font-size: 32px;
    font-weight: 700;
    padding: 40px 10px 10px;
}

.tracks-index-section {
    display: flex;
    flex-direction: column;
    /* padding: 20px 0px; */
    /* height: 33%; */
}


.tracks-index-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 30px 40px 10px;
}

.tracks-index-title {
    color: white;
    font-size: 28px;
    font-weight: 700;
    /* padding-top: 20px; */
}

.tracks-index-link {
    /* padding-top: 30px; */
    font-size: 14px;  
    font-weight: 700;
    text-decoration: none;
    color: rgb(161, 161, 161);
}

.tracks-index-link:visited, .tracks-index-link:active {
    color: rgb(161, 161, 161);
    text-decoration: underline;
}

.tracks-index-link:hover {
    text-decoration: underline;
}


.splash-random-tracks {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    /* align-content: flex-start;
    min-height: 100vh; */
    /* margin-top: 2rem; */
    /* display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-template-areas: "....";
    gap: 5%; */
}

.albums-index-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
    height: 33%;
}

.albums-index-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px 40px 10px;
}

.albums-index-title {
    color: white;
    font-size: 28px;
    font-weight: 700;
    padding-top: 20px;
}

.albums-index-link {
    padding-top: 30px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    color: rgb(161, 161, 161);
}

.albums-index-link:visited, .albums-index-link:active {
    color: rgb(161, 161, 161);
    text-decoration: underline;
}

.albums-index-link:hover {
    text-decoration: underline;
}

.splash-random-albums {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 2rem;
    height: 100%;
    /* min-height: 100vh;
    margin-top: 2rem; */
    /* display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-template-areas: "....";
    gap: 5%; */
}