.user-playlists-index-outermost-container {
    height: 100%;
}

.user-playlists-index-padding-container {
    height: 100%;
    overscroll-behavior-y: contain;
    padding: 8px 0;
    overflow: hidden !important;
}

.user-playlists-index-flexbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-playlists-index-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}
