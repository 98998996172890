.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
}

.modal-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #211e1e;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;;
    font-weight: 700;
    height: 65%;
    max-height: 500px;
    width: 60%;
    max-width: 700px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.modal-child i{
    font-size: 1.5rem;
    position: absolute;
    top: 5%;
    right: 3%;
}

.modal-child i:hover{
    color: rgb(45, 200, 25);
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.modal-content a{
    width: 30%;
    margin: 40px 20px 20px;
}

.modal-login-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 5%;
}


.modal-login-button {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
    border-style: none;
    padding: none;
    width: 100%;
    height: 50px;
    background-color: rgb(13, 208, 58);
    cursor: pointer;
    transition: transform .3s;
}

.modal-login-button:hover {
    transform: scale(1.05);
}

.modal-signup-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    text-align: center;
}


.modal-signup-button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    border-radius: 30px;
    border-style: none;
    padding: none;
    width: 100%;
    height: 50px;
    background-color: #fdfdfd;
    color: black;
    cursor: pointer;
    transition: transform .3s;
}

.modal-signup-button:hover {
    transform: scale(1.05);
}