/* TODO Add site wide styles */
body {
    font-family: 'Montserrat', sans-serif;
    height: 100%;
}

#root {
    height: 100%;
}

