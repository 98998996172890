.playlist-menu-button {
    color: #ffffff;
}

.playlist-menu-button:hover {
    color: rgb(45, 200, 25);
}

.add-to-playlist-area {
    position: relative;
}