.add-to-playlist-area-playlist-show {
    width:50%;
    position: relative;
    display: flex;
    justify-content: center;
}

.playlist-menu-button-playlist-show {
    color: #ffffff;
}

.playlist-menu-button-playlist-show:hover {
    color: rgb(45, 200, 25);
}