.navigation-bar-container {
    /* grid-area: navBar; */
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
}

.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: #1c1c1c;
    height: 64px;
    top: 0;
    width: 100%
}

.nav-bar-right-section {
    display: flex;
    align-items: center;
}

.nav-bar-right-section li{
    display: flex;
    /* flex-direction: column; */
    /* height: 64px; */
}



.about-me-icons {
    display: flex;
    height: 64px;
    padding: 0 24px;
    align-items: center;
}


.github-link {
    display: flex;
    height: 40px;
    align-items: center;
    margin-right: 24px;
    color: #b3b3b3;
    transition-duration: .2s;
    transition-property: color;
    text-decoration: none;
}

a.github-link:hover {
    color: white;
}

.github-link i {
    margin-right: 18px;
}


.linkedin-link {
    display: flex;
    height: 40px;
    align-items: center;
    margin-right: 24px;
    color: #b3b3b3;
    transition-duration: .2s;
    transition-property: color;
    text-decoration: none;
}

a.linkedin-link:hover {
    color: white;
}

.linkedin-link i {
    margin-right: 18px;
}


.nav-signup-button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: 100px;
    margin: 10px;
    border-radius: 30px;
    border-style: none;
    border: 1px solid white;
    padding: none;
    height: 50px;
    background-color: transparent;
    color: var(--text-subdued, #aaaaaa);
    cursor: pointer;
    transition: transform .3s;
}

.navigation-bar .nav-signup-button:hover {
    transform: scale(1.02);
    color: white;
}

.nav-login-button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: 100px;
    margin: 10px;
    margin-right: 20px;
    border-radius: 30px;
    border-style: none;
    border: 1px solid var(--essential-subdued, #878787);
    padding: none;
    height: 50px;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: transform .3s;
}

.navigation-bar .nav-login-button:hover {
    transform: scale(1.02);
    background-color: rgb(228, 228, 228);
}

