.track-index-item-link {
    text-decoration: none;
}

.track-index-item {
    height: 230px;
    width: 150px;
    /* height: 20vh;
    width: 14vh; */
    /* min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 230px; */
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    /* isolation: isolate; */
    cursor: pointer;
    transition: background-color .3s;
    background: #252525;
    color: white;
    font-weight: 16px;
}


.track-index-item:hover {
    background:rgb(57, 56, 56);
}

.track-index-item-play-button {
    position: absolute;
    opacity: 0;
    right: 22px;
    bottom: 32px;
    transform: translateY(12px);
    transition: all .3s;
}

.track-index-item:hover .track-index-item-play-button {
    opacity: 1;
    transform: translateY(8px);
    /* transform: translateY(0px); */
}

.track-image {
    position: relative;
    height: 66%;
    width: 100%;
}


.track-image img{
    width: 100%;
    height: auto;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .5);
}

i.fa-solid.fa-circle-play.fa-2xl {
    font-size: 50px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .5);
    transform: scale(1);
}

i.fa-solid.fa-circle-play.fa-2xl:hover{
    transform: scale(1.08);
    color: rgb(58 239 32);
}

.fa-circle-play:before, .fa-play-circle:before {
    display: flex;
    background-color: black;
    height: 24px;
    width: 24px;
    content: "\f144";
    align-items: center;
    justify-content: center;
}



.track-title {
    font-size: 1rem;
    font-weight: 700;
    width: none;
    padding-block: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.artist-name {
    color: rgb(161, 161, 161);
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;

}

.add-to-playlist-area {
    display: flex;
    flex-direction: row-reverse;
}