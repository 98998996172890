.remove-track-area {
    width: 50%;
    display: flex;
    justify-content: center;
}

.remove-track-button {
    color: #ffffff;
}

.remove-track-button:hover {
    color: rgb(45, 200, 25);
}

