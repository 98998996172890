.track-show-flex-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    position:relative;
    /* display: grid;
    grid-template-areas:
            "sideBar navBar navBar"
            "sideBar main main"
            "sideBar main main"fec
            ;
    grid-template-columns: 30vh; */
}

.non-play-bar-section {
    width: 100%;
    display: flex;
    /* padding-bottom: 80px; */
}

.track-show-nav-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 250px;
    /* min-width: 800px; */
    left: 0;
    position: relative;
    height: 100%;
}



.track-show-container {
    /* grid-area: main; */
    background-color: #1c1c1c;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 3rem;
    padding-bottom: 80px;
    height: 100%;
    min-height: 100vh;
    /* box-sizing: border-box; */
}

.track-show-header {
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-bottom: 2.5vh;
}

.track-show-image {
    margin-right: 24px;
    display:flex;
    height: 100%;
    width: 20%;
    min-width: 200px;
    max-width: 250px;
}

.track-show-image img {
    max-width: 100%;
    max-height: 100%;
}


.track-show-info {
    display: flex;
    flex-direction: column;
    white-space: normal;
    /* overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; */
}

.extra-info {
    display:flex;
    flex-direction: row;
}

.component-type {
    font-weight: 700;
}

.track-show-info.track-title {
    font-size: 8vh;
}

.extra-info{
    font-size: 14px;
}

.artist-info .artist-name {
    color: white;
    font-weight: 700;
    margin-left: 5px;
    font-size: 14px;
}

.track-year:before {
    content: "•";
    margin: 0 4px;
}

.track-duration:before {
    content: "•";
    margin: 0 4px;
}

.track-show-item.track-show-play-area {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 54px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.track-lyrics-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.track-lyrics-header{
    display: flex;
    align-items:center;
    padding: 20px 0px;
    font-size: 26px;
    font-weight: 700;
    width: 60%;
    height: 100px;
}


.artist-link-container {
    width: 40%;
    display: flex;
    align-items: center;
}

.artist-link-container-item.artist-icon {
    /* width: 50%; */
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}

.artist-icon img {
    border-radius: 50px;
    width: 84px;
    height: 84px;
}

.artist-link-container i {
    width: 70px;
    height: auto;
    margin-right: 20px;
}

.artist-link-container-item .artist-and-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.artist-header-title {
    margin-bottom: 10px;
}


.track-lyrics {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    color: hsla(0, 0%, 100%, .7);
}

.track-lyrics p{
    margin-top: 10px;
    margin-bottom: 10px;
}