.login_page {
    width: 100%;
    max-width: 450px;
    margin: 0px auto;
    
}

.logo_header {
    display: flex;
    justify-content: center;
    padding:20px;
    border-bottom: 1px solid rgb(217, 218, 220);
}

.logo_header h2 {
    text-align: center;
    margin-block: auto;
    padding-left: 5px;
    font-weight: 700;
    font-size: 24px;
}

#login_form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login_form h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    margin-top: 20px;
}

.login_labels label {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 11px;
}

.login_labels {
    width: 100%;
}

.login_labels input {
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 10px 20px 10px 10px;
}

.login_errors {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: white;
    padding: 10px 0px 10px 0px;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    background-color: red;
}

.no-errors {
    display: none;
}


#login_form button {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin: 20px;
    border-radius: 30px;
    border-style: none;
    padding: none;
    width: 100%;
    height: 50px;
    background-color: rgb(13, 208, 58);
    cursor: pointer;
    transition: transform .3s;
}

#login_form button:hover {
    transform: scale(1.01);
}

.login_break {
        margin: 12px 0px 20px;
        flex: 1 1 0%;
        border-top: 1px solid rgb(217, 218, 220);
}

.signup_link p {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    
}

.login_page .signup_button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 30px;
    border-style: none;
    border: 1px solid var(--essential-subdued, #878787);
    padding: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    color: var(--text-subdued, #6a6a6a);
    cursor: pointer;
    transition: transform .3s;

}

.login_page .signup_button:hover {
    transform: scale(1.01);
}